import { parsePhoneNumber } from 'libphonenumber-js/min'
import { useApi } from '~/composables/useApi'

export function email (value: string) {
  // eslint-disable-next-line
  return /^(?:[A-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9]{2,}(?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i.test(value)
}

export function password (value: string) {
  // Min 5 chars
  // Special chars allowed, but not required
  // Nums not required
  // return /^[A-Za-zА-Яа-я\d$@$!%*#?&^]{5,}$/g.test(value)

  // return /\d{5,}/g.test(value)
  return value.length >= 4
}

export function phoneNumber (phoneNumber: string) {
  try {
    if (!phoneNumber) {
      return true
    }
    return parsePhoneNumber(phoneNumber ?? '')?.isValid() ?? false
  } catch (e) {
    return false
  }
}

export async function uniqueUsername (username: string) {
  if (!username) {
    return
  }

  const api = useApi()
  const payload = await api.authApi.checkUsernameUnique({ username })

  return payload.data?.isUnique ?? true
}

export function authCode (code: string) {
  return code.length === 0 || code.length === 4
}

export function telegramUsername (username: string) {
  if (!username) {
    return true
  }

  const isValidPhoneNumber = phoneNumber(username)
  if (isValidPhoneNumber) {
    return true
  }

  // Регулярное выражение для стандартных и премиум ников
  const regex = /^@?[a-zA-Z0-9_]{4,32}$/
  // Регулярное выражение для ников в подкаталоге /m/
  const mRegex = /^@?[a-zA-Z0-9_]{1,32}$/

  try {
    const url = new URL(username)
    const pathSegments = url.pathname.split('/').filter(segment => segment)

    if (url.hostname !== 't.me') { return false }

    // Проверка для формата /m/someid
    if (pathSegments.length === 2) {
      return mRegex.test(pathSegments[1])
    }

    // Проверка для стандартного формата /username
    if (pathSegments.length === 1) {
      return regex.test(pathSegments[0])
    }

    return false
  } catch (e) {
    // Проверка для формата @username
    return regex.test(username)
  }
}

export function ymCounter (counter: string) {
  if (!counter) {
    return true
  }

  return /^-?\d{5,8}$/.test(counter)
}

export function ymToken (token: string) {
  if (!token) {
    return true
  }

  return /^[A-Za-z0-9-_]{58}$/.test(token) || /^[A-Za-z0-9-_]{61}$/.test(token)
}

export function gaCounter (counter: string) {
  if (!counter) {
    return true
  }
  const isGaV3 = /[A-Z]+-\d+-\d+/.test(counter) || /^\d+$/.test(counter) || /^(G|UA|YT|MO)-[a-zA-Z0-9-]+$/.test(counter) || /^(G|UA|YT|MO)-[a-zA-Z0-9-]+$/.test(counter)
  const isGaV4 = /G-\d+/g.test(counter)

  return isGaV3 || isGaV4
}

export function gaToken (token: string) {
  if (!token) {
    return true
  }

  return /^[A-Za-z0-9-_]{22}$/.test(token)
}

export function roistatCounter (counter: string) {
  if (!counter) {
    return true
  }

  return /^[a-f0-9]{32}$/.test(counter)
}

export function coMagicCounter (counter: string) {
  if (!counter) {
    return true
  }

  return /^[A-z0-9]{32}$/.test(counter)
}

export function coMagicToken (token: string) {
  if (!token) {
    return true
  }

  return /^[A-z0-9]{40}$/.test(token)
}

export function callTouchCounter (counter: string) {
  if (!counter) {
    return true
  }

  return /^[A-z0-9]{8}$/.test(counter)
}

export function callTouchToken (token: string) {
  if (!token) {
    return true
  }

  // eslint-disable-next-line no-useless-escape
  return /^[A-z0-9\/]{45}$/.test(token)
}

export function callTrackingCounter (counter: string) {
  if (!counter) {
    return true
  }

  return /^[0-9]{1,6}$/.test(counter)
}

export function callTrackingToken (token: string) {
  if (!token) {
    return true
  }

  // eslint-disable-next-line no-useless-escape
  return /^[A-z0-9\/]{64}$/.test(token)
}
